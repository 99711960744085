export interface SwitchItemProps {
    state?: SwitchItemState;
    size?: "md" | "sm" | "xs";
    label?: string;
    icon?: string;
}

export interface SwitchGroupProps {
    size?: "md" | "sm" | "xs";
    groupLabel?: string;
    value: SwitchItemProps[];
}

export enum SwitchItemState {
    standard = "standard",
    active = "active",
    disabled = "disabled",
}
